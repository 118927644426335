/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    em: "em",
    code: "code",
    img: "img"
  }, _provideComponents(), props.components), {Notes} = _components;
  if (!Notes) _missingMdxReference("Notes", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In ", React.createElement(_components.a, {
    href: "/qml/glossary/hybrid_computation"
  }, "hybrid computation"), ", algorithms\nmay consist of a mixture of classical and quantum components."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 37.34177215189873%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABcklEQVQoz22R3WvTYBTG8/97J16o7FZFceBgzAtnS7d2Mg1dSbaRpim21mYmTfLm633z2fyEyGTofnA451ych4fzaEW8JV6NSYM1/9LxP13XPbrfdy1cTxHXrwisMfuiIs1SmqYldG0iZ4hwbZpmj1KStm37o6quqaqSdt/1QvfVC7alokx3UCoWjs1b22JkL7gzBiSLD3jmkHPD4NnnU470b9RJhGt+ZHn5hthdPrTai2rTpcPIcdgFAYHvcbJeof9YUeUpcZZT54qZbXEwPuP02oA0ZjM7ZqEf4t1+pdxtkTL5+x7t9eScp6MhpnFFJ3N++h6yUCiZIbYGO+sLbRoTZAnffQ+hFFUhaWSB2NqsJgdYF++ohYC2QSuqijDPoCrRjSueDD5xqF9yZxncDJ5jDl8QzWdc2HNeWre8vzGpRQR5jhIe7vwM15myl5KubdEeJraJQo5mUyb2nKYsUOkOmfj9nBUFjv+LjQj/hMDj/AYhfgpViOiaIgAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"hybrid graph\"\n        title=\"hybrid graph\"\n        src=\"/static/cf461abdf86c316454bb4967806114ab/f058b/hybrid_graph.png\"\n        srcset=\"/static/cf461abdf86c316454bb4967806114ab/c26ae/hybrid_graph.png 158w,\n/static/cf461abdf86c316454bb4967806114ab/6bdcf/hybrid_graph.png 315w,\n/static/cf461abdf86c316454bb4967806114ab/f058b/hybrid_graph.png 630w,\n/static/cf461abdf86c316454bb4967806114ab/40601/hybrid_graph.png 945w,\n/static/cf461abdf86c316454bb4967806114ab/5c744/hybrid_graph.png 1206w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "In PennyLane, these units of quantum computations are represented using an\nobject called a ", React.createElement(_components.em, null, "quantum node"), ", or ", React.createElement(_components.code, null, "QNode()"), ". A quantum node\nconsists of a quantum function (such as a ", React.createElement(_components.a, {
    href: "/qml/glossary/variational_circuit"
  }, "variational circuit"), "), and a device on which it executes."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/dcbaeb4f5f67bb668736de46f87056a5/qnode.svg",
    alt: "qnode"
  })), "\n", React.createElement(Notes, {
    title: "Note",
    type: "success",
    text: "In PennyLane, quantum nodes can be constructed by using either the `qnode()` decorator, or the `QNode()` constructor."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
